
import React from 'react';

export default function AdminPage() {
    return (
        <div>
            <h1>Admin</h1>
        </div>
    );
}
